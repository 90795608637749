<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Estado de cuenta</h1>

    <div class="invoice-div-bottom-div">
      <div class="container global-list-main-container">
        <b-tabs type="is-boxed">
          <b-tab-item
            label="Cambios en estado de cuenta"
            icon="chart-timeline-variant"
            style="padding-top: 2rem"
          >
            <BalanceDetailsGraph
              :objectInformation="objectInformation"
            ></BalanceDetailsGraph>
          </b-tab-item>
          <b-tab-item
            label="Movimientos"
            icon="clipboard-list-outline"
            style="padding-top: 2rem"
          >
            <BalanceDetails
              :objectInformation="objectInformation"
            ></BalanceDetails>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import BalanceDetails from "./BalanceDetails.vue";
import BalanceDetailsGraph from "./BalanceDetailsGraph";

// @ is an alias to /src
export default {
  name: "BalanceDetailsTabs",
  props: ["objectInformation"],
  components: {
    BalanceDetails,
    BalanceDetailsGraph,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {},
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
