<template>
  <div class="container global-list-main-container">
    <div class="general-balance-all-graph-container">
      <div
        class="general-balance-graph-single-container-medium global-border-radius global-border-shadow"
      >
        <apexchart
          type="line"
          width="90%"
          ref="providerArticlesChangesChart"
          :options="changesChart.chartOptions"
          :series="changesChart.series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import Cleave from "cleave.js";

const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "BalanceDetailsGraph",
  props: ["objectInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      changesChart: {
        chartOptions: {
          chart: {
            type: "line",
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
              },
            },
          },
          markers: {
            size: 6,
            strokeOpacity: 0.9,
          },
          stroke: {
            curve: "straight",
          },
          title: {
            text: "Cambios en saldo",
            align: "center",
            offsetY: 15,
            style: {
              fontWeight: "bold",
              fontSize: "1.1rem",
            },
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            type: "datetime",
            labels: {
              format: "dd/MM/yyyy",
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val ? parseFloat(val).toFixed(2) : 0;
              },
            },
          },
          tooltip: {
            x: {
              format: "dd/MM/yyyy",
            },
          },
          legend: {
            style: {
              fontSize: "1.1rem",
            },
            onItemClick: {
              toggleDataSeries: true,
            },
            onItemHover: {
              highlightDataSeries: true,
            },
          },
        },
        series: [
          {
            name: "Saldo final",
            data: this.objectInformation.BALANCES.map((singleChange) => {
              return [singleChange.FECHA_MOD, singleChange.SALDO_F];
            }),
          },
        ],
      },
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    this.updateSeries();
  },
  methods: {
    // Update the data in the graph
    updateSeries() {
      let data = [];

      for (let i = 0; i < this.objectInformation.BALANCES.length; i++) {
        data.push([
          this.objectInformation.BALANCES[i].FECHA_MOD,
          this.objectInformation.BALANCES[i].SALDO_F,
        ]);
      }
      this.changesChart.chartOptions = {
        ...this.changesChart.chartOptions,
      };
      this.changesChart.series = [data];
    },
  },
  watch: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Bank/style/GeneralResumeeBank.css";
@import "../../Global/style/Global.css";
</style>
