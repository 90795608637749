/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de clientes: <b>{{ clients.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="clients"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Brands"
        :checked-rows.sync="checkedClients"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_CLI}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="CLAVE_CLI"
      >
        <template #empty>
          <div class="has-text-centered">No hay clientes</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
          >
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="showBalanceData(props.row)"
                    >Ver detalles del saldo</b-button
                  >
                  <b-button
                    v-if="
                      companyPaymentPlanModules &&
                      companyPaymentPlanModules.treasury
                        .addClientManualBalanceEntry === 'S' &&
                      permissions &&
                      permissions.treasury.addClientManualBalanceEntry === 'S'
                    "
                    type="is-primary"
                    @click="openAddManualMovement(props.row)"
                    >Agregar movimiento manual</b-button
                  >
                  <b-button
                    v-if="
                      companyPaymentPlanModules &&
                      companyPaymentPlanModules.treasury.editClientCredit ===
                        'S' &&
                      permissions &&
                      permissions.treasury.editClientCredit === 'S'
                    "
                    type="is-primary"
                    @click="openChangeClientCredit(props.row)"
                    >Editar crédito disponible</b-button
                  >
                  <b-button
                    type="is-primary"
                    @click="downloadClientBalanceReport(props.row)"
                    >Descargar estado de cuenta</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import BalanceDetailsTabs from "./BalanceDetailsTabs";
import AddManualMovementClientBalance from "./AddManualMovementClientBalance";
import EditClientCredit from "./EditClientCredit";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "ClientsBalances",
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      checkedClients: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_CLI",
          label: "Clave del cliente",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortyByKey,
        },
        {
          field: "NOMBRE_EMPRESA",
          label: "Razón social",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "RFC_CLI",
          label: "RFC",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "LIMITE_CRED",
          label: "Limite de crédito",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "CRED_DISPONIBLE",
          label: "Crédito disponible",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "BALANCE_FIN",
          label: "Estado de cuenta actual",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
      masks: Masks,
      refreshLoading: true,
    };
  },
  async created() {},
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));
      allPromises.push(this.$store.dispatch("GETBANKS"));
      allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    showBalanceData(balanceInformation) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: BalanceDetailsTabs,
        props: {
          objectInformation: balanceInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async downloadClientBalanceReport(clientInformation) {
      try {
        this.reloadInformation();
        let response = await this.$store.dispatch("GETCLIENTBALANCEREPORT", {
          clientId: clientInformation._id,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el pdf`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        const linkSource = `data:application/pdf;base64,${response.pdfData}`;
        const downloadLink = document.createElement("a");
        const fileName = response.pdfName;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el pdf`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openAddManualMovement(clientInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddManualMovementClientBalance,
        props: {
          clientId: clientInformation._id,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openChangeClientCredit(clientInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: EditClientCredit,
        props: {
          clientId: clientInformation._id,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    sortyByKey(a, b, isAsc) {
      if (isAsc) {
        return a.CLAVE_CLI.localeCompare(b.CLAVE_CLI, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      } else {
        return b.CLAVE_CLI.localeCompare(a.CLAVE_CLI, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };
        if (clientWithProps.LISTA_PRECIOS_PRE) {
          clientWithProps.defaultPriceList =
            clientWithProps.LISTA_PRECIOS_PRE.CLAVE_LISTAP;
          clientWithProps.CRED_DISPONIBLE =
            clientWithProps.LIMITE_CRED - clientWithProps.BALANCE_CRED;
        }
        return clientWithProps;
      }).filter((singleClient) => {
        return singleClient.TIPO === "CL";
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
