<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar movimiento manual</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Descripción (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': movementRequiredErrors.DESCRIPCION }"
        :message="{
          'La descripción del movimiento manual no es valido':
            movementRequiredErrors.DESCRIPCION,
        }"
      >
        <b-input
          placeholder="Ejemplo: Ajuste de saldo"
          expanded
          v-model="newMovement.DESCRIPCION"
          required
          icon="clipboard"
        ></b-input>
      </b-field>
      <b-field
        label="Cantidad (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': movementRequiredErrors.CANTIDAD }"
        :message="{
          'La cantidad del movimiento manual no es valido':
            movementRequiredErrors.CANTIDAD,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          :required="true"
          v-model="newMovement.CANTIDAD"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin" expanded>
      <b-field
        label-position="inside"
        label="Fecha (Requerido)"
        :type="{ 'is-danger': movementRequiredErrors.FECHA }"
        :message="{
          'La fecha no es valida': movementRequiredErrors.FECHA,
        }"
        expanded
      >
        <b-datepicker
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha"
          icon="calendar-today"
          required
          v-model="newMovement.FECHA"
          trap-focus
        >
        </b-datepicker>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="addManualMovement()"
        >Añadir movimiento manual</b-button
      >
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import moment from "moment";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";

// @ is an alias to /src
export default {
  name: "AddManualMovementClientBalance",
  props: ["clientId"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      newMovement: {
        FECHA: new Date(),
        DESCRIPCION: "",
        CANTIDAD: "",
      },
      movementRequiredErrors: {
        FECHA: false,
        DESCRIPCION: false,
        CANTIDAD: false,
      },
      moment: moment,
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves a new manual movement in the database, display messages according to the status
     */
    async addManualMovement() {
      try {
        this.movementRequiredErrors = validateFields(
          this.newMovement,
          this.movementRequiredErrors
        );

        if (checkValidationErrors(this.movementRequiredErrors)) {
          let response = await this.$store.dispatch("ADDCLIENTBALANCEENTRY", {
            clientId: this.clientId,
            movementInformation: this.newMovement,
          });
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el movimiento manual",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error el movimiento manual en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el movimiento manual en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el movimiento manual en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
