<template>
  <div class="container add-global-modal-main-container">
    <h3 class="form-division">Saldo actual</h3>
    <b-field
      label-position="inside"
      label="Restante total"
      class="special-margin"
    >
      <MoneyInput
        currency="MXN"
        :digits="5"
        placeholder="Ejemplo: 16"
        :required="false"
        v-model="currentObjectInformation.BALANCE_FIN"
        :disabled="true"
      >
      </MoneyInput>
    </b-field>

    <b-progress
      :rounded="false"
      type="is-success"
      size="is-medium"
      :value="
        currentObjectInformation.BALANCE_FIN
          ? currentObjectInformation.BALANCE_FIN
          : 0
      "
      :max="
        currentObjectInformation.BALANCE_FIN
          ? currentObjectInformation.BALANCE_FIN
          : 0
      "
      show-value
      format="raw"
      :precision="2"
      :keep-trailing-zeroes="true"
      locale="es-MX"
    >
      {{ currentObjectInformation.BALANCE_FIN | money("MXN", 2) }}
    </b-progress>

    <h3 class="form-division">Movimientos realizados</h3>

    <div class="invoice-div-bottom-div">
      <div class="table-header providers-list-global table-header-border">
        Cantidad de movimientos:
        <b>{{ currentMovements.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="currentMovements"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="balanceInformation"
        custom-row-key="RENGLON"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        class="purchase-order-table"
        :selected.sync="selected"
        default-sort="NUM_FOLIO"
      >
        <template #empty>
          <div class="has-text-centered">No hay movimientos</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="Folio" field="NUM_FOLIO" sortable searchable>
            <span v-if="!checkIfValid(props.row.NUM_FOLIO)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.NUM_FOLIO }}</span>
          </b-table-column>

          <b-table-column
            label="Tipo de movimiento"
            field="TIPO_FOR"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.TIPO_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.TIPO_FOR }}</span>
          </b-table-column>

          <b-table-column
            label="Tipo de operación"
            field="TIPO_OPERACION"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.TIPO_OPERACION)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.TIPO_OPERACION }}</span>
          </b-table-column>

          <b-table-column
            label="Folio de la operación"
            field="CVE_OPERACION"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.CVE_OPERACION)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.CVE_OPERACION }}</span>
          </b-table-column>

          <b-table-column label="Cantidad" field="CANTIDAD" sortable searchable>
            <span v-if="!checkIfValid(props.row.CANTIDAD)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.CANTIDAD | money("MXN", 2) }}</span>
          </b-table-column>

          <b-table-column
            label="Saldo final"
            field="SALDO_F"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.SALDO_F.toString())">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.SALDO_F.toString() | money("MXN", 2) }}</span>
          </b-table-column>

          <b-table-column
            label="Fecha"
            field="formatedDate"
            sortable
            searchable
            :custom-sort="sortByDate"
          >
            <span v-if="!checkIfValid(props.row.formatedDate)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.formatedDate }}</span>
          </b-table-column>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "BalanceDetails",
  props: ["objectInformation"],
  data() {
    return {
      currentObjectInformation: this.objectInformation,
      currentMovements: [],
      perPage: 25,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      TOTAL_PAGADO: 0,
    };
  },
  mounted() {
    moment.locale("es");
    this.currentMovements = this.currentObjectInformation.BALANCES.map(
      (singleBalance) => {
        const singleBalanceWithProps = { ...singleBalance };

        singleBalanceWithProps.formatedDate = moment(
          singleBalanceWithProps.FECHA_MOD
        ).format("DD-MM-YYYY HH:mm");
        singleBalanceWithProps.TIPO_FOR =
          singleBalance.TIPO === "I" ? "Ingreso" : "Egreso";
        singleBalanceWithProps.CANTIDAD = String(singleBalance.CANTIDAD);
        singleBalanceWithProps.SALDO_F = String(singleBalance.SALDO_F);

        return singleBalanceWithProps;
      }
    );
  },
  methods: {
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_MOD).getTime() || -Infinity) -
          (new Date(a.FECHA_MOD).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_MOD).getTime() || -Infinity) -
          (new Date(b.FECHA_MOD).getTime() || -Infinity)
        );
      }
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
