<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Editar crédito disponible</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': movementRequiredErrors.CANTIDAD }"
        :message="{
          'La cantidad del movimiento manual no es valido':
            movementRequiredErrors.CANTIDAD,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          :required="true"
          v-model="newMovement.CANTIDAD"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="editClientCredit()"
        >Añadir movimiento manual</b-button
      >
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import moment from "moment";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";

// @ is an alias to /src
export default {
  name: "EditClientCredit",
  props: ["clientId"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      newMovement: {
        CANTIDAD: "",
      },
      movementRequiredErrors: {
        CANTIDAD: false,
      },
      moment: moment,
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves a new manual movement in the database, display messages according to the status
     */
    async editClientCredit() {
      try {
        this.movementRequiredErrors = validateFields(
          this.newMovement,
          this.movementRequiredErrors
        );

        if (checkValidationErrors(this.movementRequiredErrors)) {
          let response = await this.$store.dispatch("EDITCLIENTCREDIT", {
            clientId: this.clientId,
            quantity: this.newMovement.CANTIDAD,
          });
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el crédito del cliente",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error el crédito del cliente en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar crédito del cliente en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el crédito del cliente en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
