<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Estados de cuenta </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item
        label="Proveedores"
        icon="account"
        :visible="
          permissions &&
          companyPaymentPlanModules &&
          permissions.accounting.getProvidersBalanceInvoices === 'S' &&
          companyPaymentPlanModules.accounting.getProvidersBalanceInvoices ===
            'S'
        "
      >
        <ProvidersBalances />
      </b-tab-item>
      <b-tab-item
        label="Clientes"
        icon="account-child"
        :visible="
          permissions &&
          companyPaymentPlanModules &&
          permissions.treasury.generateClientBalanceReport === 'S' &&
          companyPaymentPlanModules.treasury.generateClientBalanceReport === 'S'
        "
      >
        <ClientsBalances />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import ClientsBalances from "../components/ClientsBalances";
import ProvidersBalances from "../components/ProvidersBalances";
// @ is an alias to /src
export default {
  name: "Balances",
  components: {
    ClientsBalances,
    ProvidersBalances,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "accountStates");
  },
  async created() {
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));
    allPromises.push(this.$store.dispatch("GETBANKS"));
    allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));

    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
