var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container global-list-main-container"},[_vm._m(0),_c('div',{staticClass:"upper-global-hint"},[_c('b-icon',{attrs:{"icon":"lightbulb-outline","type":"is-primary"}}),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Recuerda: ")]),_vm._v(" "+_vm._s(_vm.hint)+" ")],1),_c('b-tabs',{attrs:{"type":"is-boxed"}},[_c('b-tab-item',{attrs:{"label":"Proveedores","icon":"account","visible":_vm.permissions &&
        _vm.companyPaymentPlanModules &&
        _vm.permissions.accounting.getProvidersBalanceInvoices === 'S' &&
        _vm.companyPaymentPlanModules.accounting.getProvidersBalanceInvoices ===
          'S'}},[_c('ProvidersBalances')],1),_c('b-tab-item',{attrs:{"label":"Clientes","icon":"account-child","visible":_vm.permissions &&
        _vm.companyPaymentPlanModules &&
        _vm.permissions.treasury.generateClientBalanceReport === 'S' &&
        _vm.companyPaymentPlanModules.treasury.generateClientBalanceReport === 'S'}},[_c('ClientsBalances')],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upper-global-title"},[_c('span',{},[_vm._v(" Estados de cuenta ")])])}]

export { render, staticRenderFns }